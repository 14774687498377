import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header-2';
import SEO from "../components/seo";

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3MjYz") {
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
`
const SocCertification = () => {
    const data = useStaticQuery(getdata);
    const uri = '/soc2-certification';
    const desc = data.wpgraphql.page.seo.metaDesc;
    const seo = data.wpgraphql.page.seo;
    seo.title = "SOC 2 Type 2 Certification";
    seo.opengraphPublishedTime = (new Date()).toISOString();
    seo.opengraphModifiedTime = (new Date()).toISOString();
    return (
        <>
            <Layout>
                <SEO title="SOC 2 Type 2 Certification" description={desc} canonical={uri} seo={seo} />
                <Header home={false} menu='about' />
                <main>
                    <section class="soc2-title py-5">
                        <div class="container">
                            <h2 class="page-title text-white">SOC 2 Type 2 Certification</h2>
                        </div>
                    </section>
                    <section class="py-5">
                        <div class="container">
                            <div class="row">
                                {/* <div className="card-text" dangerouslySetInnerHTML={{ __html: common.content }} /> */}
                                <div className="card-text">
                                    <section class="mainSpacing">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12 align-self-center mb-4">
                                                    <h3>FOR IMMEDIATE RELEASE</h3>
                                                    <p>PowerPatent Inc. Achieves SOC 2 Type 2 Certification for Security</p>
                                                    <p>Santa Clara, CA – [January 05, 2025] – PowerPatent Inc., a leading patent technology company pioneering the use of Machine Learning and Generative AI for patent drafting, announces it has successfully achieved SOC 2 Type 2 certification. This significant milestone demonstrates PowerPatent’s ongoing commitment to maintaining the highest level of security and data protection for its clients.</p>
                                                    <p>SOC 2 Type 2 certification is a rigorous audit procedure designed to verify that a service provider adheres to robust information security policies and procedures. By meeting these strict requirements, PowerPatent ensures that customers can trust the company with their most sensitive information and rely on the integrity of its systems.</p>
                                                    <p>“Achieving SOC 2 Type 2 certification is a testament to our unwavering dedication to data security and privacy,” said a spokesperson at PowerPatent. “Since our inception, we have placed our customers’ trust and well-being at the forefront of everything we do. This certification reflects the quality and diligence our team brings to every aspect of our platform.”</p>
                                                    <p>PowerPatent is staffed by experienced Silicon Valley patent professionals and AI technologists who strive to create top-tier software services for patent drafting and prosecution. By combining deep expertise in intellectual property law with leading-edge AI innovations, PowerPatent empowers clients to navigate the often complex world of patents seamlessly and confidently.</p>
                                                    <p>“Our mission is to provide unparalleled guidance throughout the patent process,” the spokesperson continued. “We understand that the patent system can be daunting, and our team takes pride in simplifying the path from idea to patent. SOC 2 certification further solidifies our reputation as a trusted partner for innovators worldwide.”</p>
                                                    <p>For more information about PowerPatent Inc. and its suite of AI-driven patent solutions, please visit www.powerpatent.com or contact us directly:
                                                    </p>
                                                    <p>Contact Details:<br />
                                                        <strong>PowerPatent Inc.</strong><br />
                                                        Email: info@powerpatent.com<br />
                                                        Phone: (888) 723-8320<br />
                                                        Address: 4701 Patrick Henry Dr, Building #16, Santa Clara, CA 95054</p>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 align-self-center mb-4">
                                                    <p>###</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 align-self-center mb-4">
                                                    <h3>About PowerPatent Inc.</h3>
                                                    <p>PowerPatent Inc. is a Silicon Valley-based company dedicated to creating advanced patent drafting and prosecution software. Drawing on decades of experience in patent law and AI technology, PowerPatent is committed to helping innovators protect their intellectual property with confidence, speed, and accuracy. By delivering a secure platform that meets rigorous standards such as SOC 2 Type 2, PowerPatent continues to be a trusted partner for clients seeking exceptional service in patent-related solutions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default SocCertification