import React, { useRef } from 'react';

import Banner from '../components/Banner/Banner-2';
import Header from '../components/Header/Header-2';
import Layout from '../components/Layout/Layout';
import ContactPopup from '../components/Popup/contact-popup';
import SEO from "../components/seo";

import "../styles/bootstrap.min.css";
import "../styles/style.css";
import "../styles/custom.slider.css";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const IndexPage = () => {
  const seo = {metaDesc: 'PowerPatent is the most advanced AI supported software for patent drafting'}
  const childRef = useRef();
  const openPopup = (email = '') => {
    childRef.current?.open(email);
  }

  return (
    <Layout>
      <SEO title="Home" canonical='' seo={seo} />
      <Header home={true} menu='home'/>
      <Banner openPopup={openPopup}/>
      <ContactPopup ref={childRef}></ContactPopup>
    </Layout>
  )
};

export default IndexPage;
