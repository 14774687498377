import {
  Elements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Header from '../components/Header/Header-2';
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import SignUpForm from '../components/sign-up-form';

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3MjYz") {
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
`
const SignUp = () => {
  const data = useStaticQuery(getdata);
  const uri = '/sign-up';
  const desc = data.wpgraphql.page?.seo?.metaDesc;
  const seo = data.wpgraphql.page?.seo || {};
  seo.title = "Sign Up";
  seo.opengraphPublishedTime = (new Date()).toISOString();
  seo.opengraphModifiedTime = (new Date()).toISOString();
  const stripeOpts = {};
  const stripePromise = loadStripe("pk_live_xbFWoZZdLjjFd1NJL2nKU4yO");
  return (
    <>
      <Layout>
        <SEO title="Sign Up" description={desc} canonical={uri} seo={seo} />
        <Header home={false} menu='pricing' />
        <main>
          <section class="pricing-banner-title py-5">
            <div class="container">
              <h2 class="page-title text-white">Sign Up</h2>
            </div>
          </section>

          <Elements stripe={stripePromise} options={stripeOpts}>
            <SignUpForm/>
          </Elements>
        </main>
        {/* </div> */}
      </Layout>
    </>
  )
}
export default SignUp