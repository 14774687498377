import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';

import CustomPopup from './Popup/custom-popup';
import { isValidEmail, isSSR } from '../utils/utils'
///////////////////////////////////////////////////////////////////////////////////////
const SignUpForm = () => {
  const API_URL = "https://api.trustyip.com";
  const DRY_RUN = 0; //  dry_run = 1 for stripe testing, dry_run = 0 for stripe live
  const alertRef = useRef();
  const [visibility, setVisibility] = React.useState(false);
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [isNameError, setIsNameError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signUpTitleMessage, setSignUpTitleMessage] = useState("");
  const [signUpMessage, setSignUpMessage] = useState("");

  const [planList, setPlanList] = useState([]);
  const [plan, setPlan] = useState({});
  const [promotionCode, setPromotionCode] = useState("");
  const [numberOfLicense, setNumberOfLicense] = useState(1);
  const [payment, setPayment] = useState({});

  const stripe = useStripe();
  const elements = useElements();
  //
  const getPlanList = () => {
    const planListVal = JSON.parse(localStorage?.getItem("planList"));
    return planListVal || [];
  }

  const getPlan = () => {
    const params = new URLSearchParams(window.location.search);
    const planId = params.get('planId') || 0;
    const planListVal = JSON.parse(localStorage?.getItem("planList")) || [];
    return planListVal.find(p => p.id === parseInt(planId)) || {};
  }

  const getPlanId = () => {
    if (!plan.id) {
      const params = new URLSearchParams(window.location.search);
      return parseInt(params.get('planId')) || 0;
    } else {
      return plan.id;
    }

  }
  //
  const popupCloseHandler = (e) => {
    setVisibility(e);
    //
    setProcessingTo(false);
  };
  //
  const increaseLicense = () => {
    setNumberOfLicense(numberOfLicense => parseInt(numberOfLicense) + 1);
  }

  const decreaseLicense = () => {
    const v = parseInt(numberOfLicense) - 1;
    setNumberOfLicense(numberOfLicense => (v < 1 ? 1 : v));
  }

  // Calculate
  const cal = () => {
    const data = {
      plan_id: getPlanId(),
      discount_code: promotionCode,
      total_licence: numberOfLicense,
      dry_run: DRY_RUN
    }
    fetch(`${API_URL}/payments/cal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((jsonRes) => {
        setPayment(payment => jsonRes.data);
      });
  }

  // submit sign up
  const submitSignUp = async () => {
    // Validate
    if (name.trim() === "") {
      setIsNameError(true);
      return;
    } else {
      setIsNameError(false);
    }

    if (!isValidEmail(email)) {
      setIsEmailError(true);
      return;
    } else {
      setIsEmailError(false);
    }

    if (password.trim().length < 8) {
      setIsPasswordError(true);
      return;
    } else {
      setIsPasswordError(false);
    }
    ///////////////////////////////////////////////////////////////////////////
    setProcessingTo(true);
    console.log("submit sign up");

    // get token
    try {
      const card = elements.getElement(CardElement)
      const { token, error } = await stripe.createToken(card)
      if (error) {
        console.log(error);
        if (error.message) {
          alert(error.message)
        } else {
          alert('Can Not Create Stripe Token. Please try again!');
        }
        setProcessingTo(false);
        return;
      }
      // submit sign up
      // {
      //   "email": "corp87@dev.trustyip.com",
      //   "password": "Abc123456@#",
      //   "full_name": "Kevin",
      //   "site": "powerpatent.com",
      //   "plan_id": 10,
      //   "token": "tok_visa",
      //   "note": "first charged",
      //   "total_licence": 5,
      //   "dry_run": 1
      // }
      const data = {
        email: email,
        password: password,
        full_name: name,
        site: "powerpatent.com",
        plan_id: plan.id,
        token: token.id,
        note: "first charged",
        total_licence: numberOfLicense,
        dry_run: DRY_RUN
      }
      fetch(`${API_URL}/businesses/local/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((jsonRes) => {
          console.log(jsonRes);
          if (jsonRes.error) {
            setSignUpTitleMessage("Error");
            setSignUpMessage(jsonRes.message);
          } else {
            setSignUpTitleMessage("Congratulation");
            setSignUpMessage(`Thank you for signing up. To <a href="https://app.powerpatent.com/docket/auth" target="_blank" class="text-decoration-none text-waning">Powerpatent Application</a>`);
          }
          openPopup();
        }).catch((error) => {
          console.log(error);
          setProcessingTo(false);
        });;

    } catch (err) {
      setCheckoutError(err.message);
      console.log(err)
    }

    //

    setProcessingTo(false);
  }
  const openPopup = () => {
    alertRef.current?.open();
  }
  //
  useEffect(() => {
    // No planId
    const params = new URLSearchParams(window.location.search);
    const planId = params.get('planId') || 0;
    if (!planId) {
      window.location.href = '/pricing';
      return;
    }
    //
    setPlanList(getPlanList);
    setPlan(getPlan());
    //
    cal();
  }, [numberOfLicense]);

  return (
    <div class="container py-3">
      <div class="row justify-content-center mb-3">
        <div class="col-md-8">
          <div class="container py-3">
            <div class="contact-form rounded-2 shadow">
              <h2 class="contact-tagline pb-4">Sign Up</h2>
              <Form>

                <Form.Group className="mb-3" controlId="name">
                  <Form.Label column sm="4">
                    Name
                  </Form.Label>
                  <Form.Control type="text" placeholder="Your name" value={name} onChange={e => setName(e.target.value)} />
                  {
                    isNameError &&
                    <Form.Text className="text-danger">
                      Name is invalid!
                    </Form.Text>
                  }

                </Form.Group>

                <Form.Group className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column sm="4">
                    Email
                  </Form.Label>
                  <Form.Control type="text" placeholder="email@example.com" value={email} onChange={e => setEmail(e.target.value)} />
                  {
                    isEmailError &&
                    <Form.Text className="text-danger">
                      Email is invalid!
                    </Form.Text>
                  }
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Password
                  </Form.Label>
                  <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                  {
                    isPasswordError &&
                    <Form.Text className="text-danger">
                      Password must have at least 8 characters
                    </Form.Text>
                  }
                </Form.Group>
                {
                  (plan && plan.id != 9) &&
                
                <Form.Group className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Number of License
                  </Form.Label>
                  <div class="input-group mb-3">
                    <button class="btn btn-outline-secondary shadow-none" type="button" id="button-addon1" onClick={decreaseLicense}>-</button>
                    <input type="text" class="form-control" style={{ textAlign: "center", fontWeight: "bold" }} value={numberOfLicense} readOnly aria-label="Number of License" aria-describedby="button-addon1" />
                    <button class="btn btn-outline-primary shadow-none" type="button" id="button-addon2" onClick={increaseLicense}>+</button>
                  </div>
                </Form.Group>
                }
                
                <Form.Group className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Selected Plan
                  </Form.Label>
                  {
                    plan &&
                    <span><strong>{plan.title}</strong> - <strong> {numberOfLicense} </strong> license{numberOfLicense > 1 ? "s" : ""} - <strong>{plan.content}</strong></span>
                  }
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Total Payment
                  </Form.Label>
                  {
                    payment &&
                    <strong>${payment.total_amount_charged}</strong>
                  }
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Charge Date
                  </Form.Label>
                  {
                    payment &&
                    <strong>{payment.charge_date}</strong>
                  }
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column sm="5">
                    Promotion Code (Optional)
                  </Form.Label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" value={promotionCode} onChange={e => setPromotionCode(e.target.value)} placeholder="Promotion Code" aria-label="Promotion Code" aria-describedby="button-addon2" />
                    <button onClick={cal} class="btn btn-outline-primary shadow-none" type="button" id="button-addon2">Apply</button>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Card Information
                  </Form.Label>
                  <CardElement></CardElement>
                </Form.Group>
              </Form>
              <div class="pt-3 text-center">
                <button id="reqBtn" disabled={isProcessing} onClick={submitSignUp} class="btn btn-lg btn-warning px-4" style={{ minWidth: 50 + '%' }}>Submit Payment</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomPopup ref={alertRef} show={false}
        title="" onClose={popupCloseHandler} >
        <div>
          <h3 className="text-center fw-bold mb-4">{signUpTitleMessage}</h3>
          <p><div dangerouslySetInnerHTML={{__html: signUpMessage}}></div></p>
        </div>
      </CustomPopup>
    </div>
  )
}

export default SignUpForm