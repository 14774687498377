import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha";
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import Header from '../components/Header/Header-2';
import { getBigCompanySubject, validateSubscriberData } from '../utils/utils';
/////////////////////////////////////////////////////////////////////////////////
const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3MzY0") {
      uri
      title
      content
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const Contact = () => {
    const captchaRef = useRef(null);
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('Technical Support');
    //
    const data = useStaticQuery(getdata);
    const common = data.wpgraphql.page;
    //
    const submit = async (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if (!token) {
            alert('Please Confirm You Are Not a Robot!');
        } else {
            // check 
            const response = await fetch("https://api.trustyip.com/recaptcha/verify?token=" + token);
            const responseJson = await response.json();
            if ((responseJson.data.success) || (responseJson.data['error-codes'][0] === 'timeout-or-duplicate')) {
                if (firstName && lastName && email && phone && subject && message) {
                 const data = {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    name: `${firstName} ${lastName}`,
                    phone: phone || '',
                    subject: `${getBigCompanySubject(email)}[PowerPatent - ${subject}] New message from ${email}`,
                    message: message,
                    site: 'powerpatent.com'
                }

                // validate data
                if (!validateSubscriberData(data)) {
                    return;
                }

                // submit campagin
                // submitActiveCampaignForm({ name: `${firstName} ${lastName}`, email: email, phone: phone }, false);
                // send mail
                fetch("https://api.trustyip.com/subcribers/add", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json"
                    },
                    "body": JSON.stringify(data)
                    })
                    .then(response => response.json())
                    .then(response => {
                        alert('Thank you for contacting us. We will feedback to you soon!!!');
                        // reset
                        setEmail('');
                        setFirstName('');
                        setLastName('');
                        setPhone('');
                        setMessage('');
                    })
                    .catch(err => {
                        alert('There is something wrong. Please try again later!');
                        console.log(err);
                    });
                // close popup
                // childRef.current?.close();
                } else {
                    alert('Please fill in all fields.');
                }
            }
        }
    }
    return (
        <>
            <Layout>
                <SEO title="Contact" description={'Contact Info'} canonical={common.uri} seo={common.seo}/>
                <Header home={false}  menu='about'/>
                <main>
                    <section class="contact-title py-5">
                        <div class="container">
                            <h2 class="page-title text-white">Contact</h2>
                        </div>
                    </section>
                    <section class="py-5">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 py-md-0 py-4">
                                    <h2 class="anim-title mb-5">Contact Details</h2>
                                    <div class="d-flex flex-column contact-desc gap-2">
                                        <p><i class="fa fa-envelope me-3"></i> <a
                                                href="mailto:info@powerpatent.com">info@powerpatent.com</a></p>
                                        <p><i class="fa fa-phone me-3"></i><a href="tel:+888-723-8320">888-723-8320</a></p>
                                        <p><i class="fa fa-map-marker me-3"></i> 4701 Patrick Henry Dr, Building #16, Santa
                                            Clara, CA 95054 </p>
                                        <iframe loading="lazy" class="w-100" height="360"
                                            src="https://maps.google.com/maps?q=4701%20Patrick%20Henry%20Dr%2C%20Building%20%2316%2C%20Santa%20Clara%2C%20CA%2095054&amp;t=m&amp;z=13&amp;output=embed&amp;iwloc=near"
                                            title="4701 Patrick Henry Dr, Building #16, Santa Clara, CA 95054"
                                            aria-label="4701 Patrick Henry Dr, Building #16, Santa Clara, CA 95054"></iframe>
                                    </div>
                                </div>
                                <div class="col-md-6 py-md-0 py-4">
                                    <div class="contact-form rounded-2 shadow">
                                        <form action="">
                                            <h2 class="contact-tagline pb-4">Want to get in touch? We’d love to hear from you.
                                            </h2>
                                            <div class="row">
                                                <div class="col-md-6 pb-4">
                                                    <input type="text" class="form-control rounded-0" placeholder="First Name" required="true" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                                                </div>
                                                <div class="col-md-6 pb-4">
                                                    <input type="text" class="form-control rounded-0" placeholder="Last Name" required="true" value={lastName} onChange={e => setLastName(e.target.value)}/>
                                                </div>
                                                <div class="col-md-6 pb-4">
                                                    <input type="text" class="form-control rounded-0" placeholder="Email" required="true" value={email} onChange={e => setEmail(e.target.value)}/>
                                                </div>
                                                <div class="col-md-6 pb-4">
                                                    <input type="text" class="form-control rounded-0" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)}/>
                                                </div>
                                                <div class="col-md-12 pb-4">
                                                    <select class="form-control rounded-0" onChange={e => setSubject(e.target.value)}>
                                                        <option value=""  disabled>How can we help</option>
                                                        <option value="Technical Support" selected>Technical Support</option>
                                                        <option value="Product Demonstration">Product Demonstration</option>
                                                        <option value="Pricing Information">Pricing Information</option>
                                                        <option value="Purchase Quote">Purchase Quote</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-12 pb-4">
                                                    <textarea type="text" rows="5" class="form-control rounded-0"
                                                        placeholder="Message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                                </div>
                                                <div class="col-md-12 pb-4">
                                                <ReCAPTCHA sitekey="6LetH-InAAAAAOJxzG4oeh59AVKhM3nj4eCxXXBI" ref={captchaRef} />
                                                </div>
                                            </div>
                                            <div class="pt-3 text-end">
                                                <button onClick={submit} class="btn btn-lg btn-warning px-4">Send</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default Contact
