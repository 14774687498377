import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { parse } from "node-html-parser";
import Parser from 'react-html-parser';
import Header from '../components/Header/Header-2';
import Layout from '../components/Layout/Layout';
import CustomPopup from '../components/Popup/custom-popup';
import Seo from "../components/seo";
import { addExternalScript, getBigCompanySubject, getFirstTextNode, isHasAbpostIndex, submitActiveCampaignForm, updateBlogContent, validateSubscriberData } from '../utils/utils';

const isSSR = () => typeof window === 'undefined' || !window.document;

function Post({ data, lang, meta, title, pageContext }) {
  const PATENT_REVIEW_TAG = 'Patent Review';
  const childRef = useRef();
  const captchaRef = useRef(null)
  const [visibility, setVisibility] = React.useState(false);
  const [email, setEmail] = useState('');
  const [sideBarName, setSideBarName] = useState('');
  const [sideBarEmail, setSideBarEmail] = useState('');
  const [sideBarPhone, setSideBarPhone] = useState('');
  const [sideBarSubject, setSideBarSubject] = useState('Software Patent');
  const [sideBarMessage, setSideBarMessage] = useState('');
  const [captchaScriptLoaded, setCaptchaScriptLoaded] = useState(false);
  const thePost = data.THE_POST;
  thePost.seo.lcpImg = thePost.featuredImage?.node.mediaItemUrl;
  const tags = thePost.tags.nodes.map(t => t.name);
  // const recentBlogs = data.OTHER_POSTS;
  // Shuffle array
  const randomPosts = data.RANDOM_POSTS.nodes.sort(() => 0.5 - Math.random());
  // 20 auto posts
  const autoPosts = data.AUTO_POSTS.nodes;
  // Get sub-array of first n elements after shuffled
  let selectedRandomPosts = randomPosts.slice(0, 5);
  const descFromAbstract = getFirstTextNode(thePost.content);
  //
  const [updatedBlogContent, setUpdatedBlogContent] = useState([]);
  ////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Process WP Domain
  //
  ////////////////////////////////////////////////////////////////////////////////////////////
  const processContent = (content) => {
    const DOMAIN_WP = 'https://wp.powerpatent.com'
    if (!content) return content;

    const postContentParser = parse(content);

    /**
     * Lazy loading images
     */
    const imgTags = postContentParser.querySelectorAll("img");
    if (imgTags && imgTags.length) {
      for (const img of imgTags) {
        // https://stackoverflow.com/questions/77744344/is-it-okay-to-use-both-fetchpriority-high-and-loading-eager-in-img-tag
        img.setAttribute("loading", "lazy");

        img.setAttribute("fetchpriority", "low");
      }
    }

    /**
     * 
     */
    const aTags = postContentParser.querySelectorAll("a");
    if (aTags && aTags.length) {
      for (const blog of aTags) {
        let href = blog.getAttribute("href");
        if (href && href.startsWith(DOMAIN_WP)) {
          blog.setAttribute("href", href.replace(DOMAIN_WP, ""));
        }
      }
    }
    return postContentParser.toString();
  };

  thePost.content = processContent(thePost.content);
  ////////////////////////////////////////////////////////////////////////////////////////////
  //
  const handleGeneratePdf = async () => {
    const jsPDF = (await import('jspdf')).default;
    const doc = new jsPDF({
      format: "a4",
      unit: "px"
    });

    doc.html(document.getElementById('blogContent'), {
      async callback(doc) {
        await doc.save(thePost.slug + ".pdf");
      },
      html2canvas: { scale: 0.5, imageTimeout: 0, svgRendering: true, useCORS: true },
      image: { type: "jpeg", quality: 0.5 },
      retina: true,
      pagesplit: true,
      enableLinks: false,
      autoPaging: 'text',
      margin: [50, 50, 20, 50]
    });
  };
  //
  //
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };
  //
  const submit = (e) => {
    e.preventDefault();
    if (email) {
      // generate pdf
      handleGeneratePdf();
      // submit campagin
      submitActiveCampaignForm({ name: email, email: email }, false);
      // close popup
      childRef.current?.close();
    } else {
      alert('Please fill in all fields.');
    }
  }
  //
  const submitSideBar = async (e) => {
    e?.preventDefault();

    if (sideBarName && sideBarEmail && sideBarPhone && sideBarSubject && sideBarMessage) {
      //
      const token = window.captchaToken;
      if (!token) {
        alert('Please Confirm You Are Not a Robot!');
      } else {
        // check 
        const response = await fetch("https://api.trustyip.com/recaptcha/verify?token=" + token);
        const responseJson = await response.json();
        if (responseJson.data.success) {

          // api data
          const data = {
            email: sideBarEmail,
            name: sideBarName,
            phone: sideBarPhone,
            subject: getBigCompanySubject(sideBarEmail) + sideBarSubject,
            message: sideBarMessage,
            site: 'powerpatent.com'
          }

          // validate data
          if (!validateSubscriberData(data)) {
            return;
          }

          const emailVal = sideBarEmail;
          // submit campagin
          submitActiveCampaignForm({ name: sideBarName || emailVal, email: emailVal }, false);

          // call api
          fetch("https://api.trustyip.com/subcribers/add", {
            "method": "POST",
            "headers": {
              "content-type": "application/json",
              "accept": "application/json"
            },
            "body": JSON.stringify(data)
          })
            .then(response => response.json())
            .then(response => {
              alert('Thank you for contacting us. We will be getting back to you shortly.');
            })
            .catch(err => {
              alert('There is something wrong. Please try again later!');
              console.log(err);
            });
          //Reset
          captchaRef.current.reset();
        }
      }
    } else {
      alert('Please fill in all fields.');
    }

  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadCaptchaScript = () => {
    if (!captchaScriptLoaded) {
      addExternalScript('https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit', () => {
        setCaptchaScriptLoaded(true);
      });
    }
  }

  const renderReCaptch = () => {
    if (document.getElementById('re-captcha')) {
      window['grecaptcha'].render('re-captcha', {
        'sitekey': '6LetH-InAAAAAOJxzG4oeh59AVKhM3nj4eCxXXBI',
        'callback': (response) => {
          window.captchaToken = response;
        }
      });
    }
  }
  //
  useEffect(() => {

    if (tags && tags.includes(PATENT_REVIEW_TAG)) {
      window.location.href = '/';
    }

    //
    setUpdatedBlogContent(updateBlogContent(thePost.content, selectedRandomPosts, autoPosts));
    // load OptinMonster
    if (window.om210904_195537) {
      window.om210904_195537.reset();
    };

    window.handleGeneratePdf = () => {
      handleGeneratePdf();
    };
    window.openPopupDownload = () => {
      childRef.current?.open();
    };
    // Google recaptcha
    window.captchaToken = '';
    window['onloadCallback'] = () => {
      renderReCaptch();
    };
    //
    // Set Google Captcha Width
    // const sideBarDiv = document.getElementById('sideBarDiv');
    // const iframes = sideBarDiv?.getElementsByTagName('iframe');
    // if (iframes && iframes.length) {
    //   iframes[0].width = "254";
    // }
  }, []);
  // check abpost-index
  const isAbpostIndex = isHasAbpostIndex(thePost.content);
  return (
    <>
      {
        tags && tags.includes(PATENT_REVIEW_TAG) ?
          (isSSR() ? '' : <script>window.location.href = '/';</script>)
          :
          <Layout>
            <Seo title={`${thePost.title}`} canonical={`/blog/${thePost.slug} `} descFromAbstract={descFromAbstract} seo={thePost.seo} />
            <Header home={false} menu='blog' />
            <main>
              <article class="px-lg-4">
                <section class="py-5 bg-white">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-9 col-sm-12 word-wrap" id="blogContent">
                        <div class="d-flex mb-5 align-items-center justify-content-between flex-wrap gap-md-0 gap-3">
                          <div class="breadcrumb mb-0">
                            <a href="#" class="breadcrumb-item text-warning">Power Patent</a>
                            <a href="/blog" class="breadcrumb-item text-warning">Blogs</a>
                            <a aria-label={thePost.title} class="breadcrumb-item text-muted" href={ "/blog/" + thePost.slug}>{thePost.title}</a>
                          </div>
                        </div>

                        {
                          thePost.featuredImage?.node?.mediaItemUrl ?
                            <img src={thePost.featuredImage?.node.mediaItemUrl} alt="Blog Image"
                              class="img-fluid w-100 mb-5" loading="eager" fetchpriority="high"></img>
                            : null
                        }

                        <article className="mb-5">
                          <header className="text-center">
                            <h1 className="card-title mb-2">
                              {thePost.title}
                            </h1>
                            <div>
                              <address class="author"><small>By</small> <a rel="author" href="#"><small>Alex Tran</small></a></address>
                              <small>on</small> <time pubdate datetime="2011-08-28" title={`${thePost.date}`}><small>{thePost.date}</small></time>
                            </div>
                          </header>
                          {!isAbpostIndex ?
                            <div class="abpost-index"><div class="px-5 py-3"><strong>Bonus:</strong> <a class="text-danger text-decoration-none fw-bold" href="javascript: openPopupDownload();">Download the entire article,</a>
                              plus exclusive action-items that will show you how to implement the tactics and tips written about in this article.</div></div>
                            : null
                          }
                          <div className="card-text post-content">{Parser(isSSR() ? thePost.content : updatedBlogContent)}</div>
                        </article>

                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div id="sideBarDiv" className="sidebar py-4 px-3">
                          <h2 className="text-center" style={{ fontSize: "25px"}}>Get A Free Consultation</h2>
                          <div>
                            <Form >
                              <Form.Group className="mb-1" controlId="sideBarName" value={sideBarName} onChange={e => setSideBarName(e.target.value)}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Your name" onFocus={loadCaptchaScript} />
                              </Form.Group>

                              <Form.Group className="mb-1" controlId="sideBarEmail" value={sideBarEmail} onChange={e => setSideBarEmail(e.target.value)}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Your email address" onFocus={loadCaptchaScript} />
                              </Form.Group>

                              <Form.Group className="mb-1" controlId="sideBarPhone" value={sideBarPhone} onChange={e => setSideBarPhone(e.target.value)}>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="Your phone" onFocus={loadCaptchaScript} />
                              </Form.Group>


                              <Form.Group className="mb-1" controlId="sideBarSubject">
                                <Form.Label>What do you need help with?</Form.Label>
                                <Form.Select aria-label="What do you need help with?" defaultValue={"Software Patent"} value={sideBarSubject} onChange={e => setSideBarSubject(e.target.value)} onFocus={loadCaptchaScript}>
                                  <option value="Software Patent" selected="true">Software Patent</option>
                                  <option value="AI Patent">AI Patent</option>
                                  <option value="AR/VR Patent">AR/VR Patent</option>
                                  <option value="Communication Patent">Communication Patent</option>
                                  <option value="Electronic Patent">Electronic Patent</option>
                                  <option value="Fin Tech Patent">Fin Tech Patent</option>Fin Tech Patent
                                  <option value="Industrial Design Patent">Industrial Design Patent</option>
                                  <option value="Medical Device">Medical Device Patent</option>
                                  <option value="Mechanical Design">Mechanical Design Patent</option>
                                  <option value="Trademark">Trademark</option>
                                </Form.Select>
                              </Form.Group>

                              <Form.Group className="mb-1" controlId="sideBarMessage" value={sideBarMessage} onChange={e => setSideBarMessage(e.target.value)}>
                                <Form.Label>How can we help you with?</Form.Label>
                                <Form.Control as="textarea" rows={2} placeholder="Your message" onFocus={loadCaptchaScript} />
                              </Form.Group>
                              <Form.Group className='mb-1'>
                                <div id="re-captcha" class="g-recaptcha" style={{ maxWidth: '250px' }}></div>
                              </Form.Group>
                              <span className="text-muted" style={{ fontSize: 10 + 'px' }}>By submitting your agree with our Privacy Policy</span>
                              <button className='btn btn-warning w-100 mt-1' onClick={e => submitSideBar(e)}>Submit</button>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </main>
            <CustomPopup
              onClose={popupCloseHandler}
              ref={childRef}
              show={false}
              title="">
              <h3 className="text-center fw-bold mb-4">Submit your email and get to <br /> download  this post as a PDF file</h3>
              <Form >
                <Form.Group className="mb-3" controlId="formBasicEmail" value={email} onChange={e => setEmail(e.target.value)}>
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <button className='btn btn-warning w-100' onClick={submit}>Submit</button>
              </Form>
            </CustomPopup>
          </Layout>
      }
    </>
  )
}

Post.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ''
}

Post.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}


export const query = graphql`
  query($neId: String, $slug: String, $catName: String) {
    OTHER_POSTS: allWpPost(limit: 4, filter: {id: {ne: $neId}}) {
      nodes {
        title
        slug
      }
    }
    RANDOM_POSTS: allWpPost(
      limit: 5, 
      filter: {tags: {nodes: {elemMatch: {name: {ne: "Patent Review"}}}}},
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        title
        slug
      }
    }
    AUTO_POSTS: allWpPost(
      limit: 20, 
      filter: {categories: {nodes: {elemMatch: {name: {eq: $catName, ne: "Patent Review"}}}}}
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        id
        uri
        title
        slug
        content
        date(fromNow: true)
        tags {
          nodes {
            name
          }
        }
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
    THE_POST: wpPost(slug: { eq: $slug }) {
      date(formatString: "LL")
      content
      id
      uri
      slug
      title
      seo {
        canonical
        focuskw
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }}}
          }
    }
  }`
export default Post;
