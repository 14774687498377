exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-free-tools-js": () => import("./../../../src/pages/free-tools.js" /* webpackChunkName: "component---src-pages-free-tools-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-3-d-printing-js": () => import("./../../../src/pages/industries/3-d-printing.js" /* webpackChunkName: "component---src-pages-industries-3-d-printing-js" */),
  "component---src-pages-industries-animal-health-js": () => import("./../../../src/pages/industries/animal-health.js" /* webpackChunkName: "component---src-pages-industries-animal-health-js" */),
  "component---src-pages-industries-artificial-intelligence-ai-js": () => import("./../../../src/pages/industries/artificial-intelligence-ai.js" /* webpackChunkName: "component---src-pages-industries-artificial-intelligence-ai-js" */),
  "component---src-pages-industries-autonomous-vehicles-js": () => import("./../../../src/pages/industries/autonomous-vehicles.js" /* webpackChunkName: "component---src-pages-industries-autonomous-vehicles-js" */),
  "component---src-pages-industries-biopharmaceuticals-js": () => import("./../../../src/pages/industries/biopharmaceuticals.js" /* webpackChunkName: "component---src-pages-industries-biopharmaceuticals-js" */),
  "component---src-pages-industries-blockchain-and-fintech-js": () => import("./../../../src/pages/industries/blockchain-and-fintech.js" /* webpackChunkName: "component---src-pages-industries-blockchain-and-fintech-js" */),
  "component---src-pages-industries-cannabis-patents-and-trademarks-js": () => import("./../../../src/pages/industries/cannabis-patents-and-trademarks.js" /* webpackChunkName: "component---src-pages-industries-cannabis-patents-and-trademarks-js" */),
  "component---src-pages-industries-chemical-products-js": () => import("./../../../src/pages/industries/chemical-products.js" /* webpackChunkName: "component---src-pages-industries-chemical-products-js" */),
  "component---src-pages-industries-communications-js": () => import("./../../../src/pages/industries/communications.js" /* webpackChunkName: "component---src-pages-industries-communications-js" */),
  "component---src-pages-industries-consumer-products-js": () => import("./../../../src/pages/industries/consumer-products.js" /* webpackChunkName: "component---src-pages-industries-consumer-products-js" */),
  "component---src-pages-industries-digital-healthcare-js": () => import("./../../../src/pages/industries/digital-healthcare.js" /* webpackChunkName: "component---src-pages-industries-digital-healthcare-js" */),
  "component---src-pages-industries-electronics-js": () => import("./../../../src/pages/industries/electronics.js" /* webpackChunkName: "component---src-pages-industries-electronics-js" */),
  "component---src-pages-industries-food-science-packaging-js": () => import("./../../../src/pages/industries/food-science-packaging.js" /* webpackChunkName: "component---src-pages-industries-food-science-packaging-js" */),
  "component---src-pages-industries-industrial-and-agricultural-biotech-js": () => import("./../../../src/pages/industries/industrial-and-agricultural-biotech.js" /* webpackChunkName: "component---src-pages-industries-industrial-and-agricultural-biotech-js" */),
  "component---src-pages-industries-industrial-products-js": () => import("./../../../src/pages/industries/industrial-products.js" /* webpackChunkName: "component---src-pages-industries-industrial-products-js" */),
  "component---src-pages-industries-infectious-diseases-js": () => import("./../../../src/pages/industries/infectious-diseases.js" /* webpackChunkName: "component---src-pages-industries-infectious-diseases-js" */),
  "component---src-pages-industries-internet-js": () => import("./../../../src/pages/industries/internet.js" /* webpackChunkName: "component---src-pages-industries-internet-js" */),
  "component---src-pages-industries-medical-device-patents-js": () => import("./../../../src/pages/industries/medical-device-patents.js" /* webpackChunkName: "component---src-pages-industries-medical-device-patents-js" */),
  "component---src-pages-industries-medical-devices-js": () => import("./../../../src/pages/industries/medical-devices.js" /* webpackChunkName: "component---src-pages-industries-medical-devices-js" */),
  "component---src-pages-industries-metaverse-js": () => import("./../../../src/pages/industries/metaverse.js" /* webpackChunkName: "component---src-pages-industries-metaverse-js" */),
  "component---src-pages-industries-nanotechnology-js": () => import("./../../../src/pages/industries/nanotechnology.js" /* webpackChunkName: "component---src-pages-industries-nanotechnology-js" */),
  "component---src-pages-industries-patent-news-js": () => import("./../../../src/pages/industries/patent-news.js" /* webpackChunkName: "component---src-pages-industries-patent-news-js" */),
  "component---src-pages-industries-prescription-otc-drugs-js": () => import("./../../../src/pages/industries/prescription-otc-drugs.js" /* webpackChunkName: "component---src-pages-industries-prescription-otc-drugs-js" */),
  "component---src-pages-industries-software-js": () => import("./../../../src/pages/industries/software.js" /* webpackChunkName: "component---src-pages-industries-software-js" */),
  "component---src-pages-industries-therapeutic-antibodies-js": () => import("./../../../src/pages/industries/therapeutic-antibodies.js" /* webpackChunkName: "component---src-pages-industries-therapeutic-antibodies-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-patent-analyzer-js": () => import("./../../../src/pages/patent-analyzer.js" /* webpackChunkName: "component---src-pages-patent-analyzer-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-soc-2-certification-js": () => import("./../../../src/pages/soc2-certification.js" /* webpackChunkName: "component---src-pages-soc-2-certification-js" */),
  "component---src-pages-submit-request-js": () => import("./../../../src/pages/submit-request.js" /* webpackChunkName: "component---src-pages-submit-request-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-video-help-js": () => import("./../../../src/pages/video-help.js" /* webpackChunkName: "component---src-pages-video-help-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

